import React, {useState, useEffect, useRef} from "react";
import AuthorizedAreas from '../../../utils/AuthorizedAreas';
import { Link } from "react-router-dom";
import useAxios from "../../../utils/hooks/useAxios";
import moment from 'moment';
import Datetime from "react-datetime";
import Modal from "react-bootstrap/Modal";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";

import "react-datetime/css/react-datetime.css";
import {REQUEST_METHOD} from "../../../utils/Constants";

const AccidentsReportManager = () => {

	const searchResultRef = useRef(null);

	const defaultForm = {

		//data check
		doneFormattingForServer: false,

		//section 1
		mostAccurateReflectsAccidentOrIncident: "", //Staff - Student - All - None
		whatTypeOfStudentGroupAreYouReportingFor:"",
		whatIsTheNameofYourSocietyCluborGroup:"",
		whatIsYourSUDepartment:"",
		whatAreYouReportingFor: "", //accident damage theft near_miss

		//section 2
		injuredPersonsFullName:"",
		injuredPersonsAddress:"",
		injuredPersonsPostcode:"",
		injuredPersonsAge:"",
		injuredPersonsInjuryType:"",
		injuredPersonsInjuryLocation:"",
		injuredPersonsFirstAid:"",
		injuredPersonsPermission:"",

		//section 3
		didTheEventHappenOnAUniversityCampus:"",
		whereDidTheEventHappen:"",
		whenDidTheAccidentHappen:"",
		pleaseDescribeHowTheEventHappened:"",
		//--Accident Injury Only
		afterTheInjuryDidAnyOfTheFollowingINJURY:[],
		whatCausedTheAccident:"",
		doAnyFirstAidKitsNeedToBeRefilled:"",
		//--Accident Damage Only
		whatHasBeenDamagedOrBroken:[],
		//Accident Damage & Incident Only
		afterTheEventDidAnyOfTheFollowingOthers:[],

		//section 4
		didAnybodyElseWitnessTheEvent:"",
		witness1Name:"",
		witness1Contact:"",
		witness2Name:"",
		witness2Contact:"",
		additionalWitnesses:"",

		//section 5
		fillerFullName:"",
		fillerEmailAddress:"",
		fillerAdditionalContact:"",

	}
	const {dispatchNotification} = useGlobalNotification();

	const [showModal,setShowModal] = useState(false);
    const [allData, setAllData] = useState([]);
	const [singleReportData, setSingleReportData] = useState(defaultForm);

	//filtering variables
	const [pageSize,setPageSize] = useState("25");
    const [paginationData, setPaginationData] = useState({
		//java
		first:true,
		last:true,
		empty:false,
		size: pageSize,
		number:0,
		numberOfElements:0,
		totalElements:0,
		totalPages: 0,

		//mine
		loaded:false,
	});

	//Advanced search
	const [searchPerformed, setSearchPerformed] =useState(false);
	const [advancedSearch, setAdvancedSearch] =useState(false);
	const [advancedSearchForm, setAdvancedSearchForm] =useState({});
	const [selectedCriteria, setSelectedCriteria] =useState([]);
	const [suDepartments, setSuDepartments] =useState([]);

	const {httpRequest}  = useAxios();

	const handleMarkReadUnread = async (id,status) => {
		httpRequest('accident_reports/activate/deactivate',REQUEST_METHOD.PATCH,{id,status}).then((data) =>{
			if(data){
				const newAllData = allData.map((data) =>{
					return data.reportId === id ? {...data,reportHasBeenRead : status} : data;
				});
				setAllData(newAllData);
			}
		});
	}

	const handleStatePropertyDeletion = (key) => {
		setAdvancedSearchForm(prevState => {
			const newState = { ...prevState };
			delete newState[key];
			return newState;
		});
	};

	const getPaginationBody = () =>{
	    
		let count=0;
		let body = [];
	
		for(let i = paginationData.number ; i < paginationData.totalPages -1; i++){
			if(count === 5) break;//show only 5 links at anytime
			body.push(i);
			count++;
		}
		return body;	
	}
	const  handleFileDownload = (response, type) =>{
		const href = URL.createObjectURL(response);
		let filename = `uonsu-accident-report.${type === "excel" ? "xls" : "pdf"}`;
		setTimeout(() => {
			if (window.infContentDisposition) {
				const match = window.infContentDisposition.match(/filename="(.+?)"/);
				if (match && match[1]) {
					filename = match[1]; // Use filename from server
				}
				window.infContentDisposition = null;
			}
			const link = document.createElement('a');
			link.href = href;
			link.setAttribute('download', filename);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		}, 1000)
	}
	const handleSearch = async (filter={},page = paginationData.number,size = paginationData.size, type="load") => {

		for (const key of selectedCriteria) {
				if (key === "submittedOn" || key === "whenDidTheAccidentHappen") {
					let dateStart = `${key}_start`;
					let endStart = `${key}_end`;

					if ((!(dateStart in advancedSearchForm) || !(endStart in advancedSearchForm))
						|| (advancedSearchForm[dateStart] === "" || advancedSearchForm[endStart] === "")
						|| (advancedSearchForm[dateStart] === null || advancedSearchForm[endStart] === null)
					) {

						dispatchNotification('error', 'Kindly fill out both start and end date ranges');
						return;
					}
				} else if (!(key in advancedSearchForm) || advancedSearchForm[key] === "" || advancedSearchForm[key] === null) {
					dispatchNotification('error', 'Kindly fill out all selected form fields or uncheck irrelevant fields');
					return;
				}
			}

		const params = new URLSearchParams(filter).toString();
		httpRequest(`accident_reports/search?pageNumber=${page}&pageSize=${size}&type=${type}&${params}`,REQUEST_METHOD.GET,advancedSearchForm,{'content-type': 'application/json'},`${(type === "pdf" || type === "excel") ? "blob" : "json"}`)
			.then((response) =>{
			switch (type){
				case "excel":
				case "pdf":
					handleFileDownload(response, type);
					break;
				default:
					if(type==="filter"){
						setSearchPerformed(true);
					}
					if (response?.data?.reports) {
						setAllData(response.data.reports.content);
						let paginationData = {...response.data.reports, loaded: true};
						delete paginationData.content;
						delete paginationData.sort;
						delete paginationData.pageable;
						setPaginationData(paginationData);
					}
					//default load bring departments
					if (response?.data?.departments) {
						setSuDepartments(response?.data?.departments);
					}
					setAdvancedSearch(false)
					//searchResultRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
				   // setTimeout(()=>setAdvancedSearch(false),1500);
			}
		});
	}
	const handleClearFilter = () =>{
		setSelectedCriteria([]);
		setAdvancedSearch(false);
		setAdvancedSearchForm({});
		handleSearch({},0,pageSize).then(()=>{
			setSearchPerformed(false);
		})
	}
	const typeBadgeSelector = (type) =>{
		switch (type){
			case "ACCIDENT - INJURY":
				return "outline-danger";
			case "ACCIDENT - DAMAGE":
				return "outline-secondary";
			case "INCIDENT":
				return "outline-dark";
			case "NEAR-MISS":
				return "outline-warning";
			default:
				return "outline-info";
		}
	}
    const viewReport = (id) =>{
		//load and show
	   httpRequest(`accident_reports/report/${id}`,'GET',{}).then((data) =>{
		   if(data?.data?.report){
			    setSingleReportData(data.data.report);
		   }
	   });

	   setShowModal(true);
   }
	const downloadExcel = (id) =>{
		//load and show
		httpRequest(`accident_reports/report/excel/${id}`,REQUEST_METHOD.GET,{},{'content-type': 'application/json'},"blob").then((response) =>{
			handleFileDownload(response,"excel");
		});
	}
	const downloadPdf = (id) =>{
		//load and show
		httpRequest(`accident_reports/report/pdf/${id}`,REQUEST_METHOD.GET,{},{'content-type': 'application/json'},"blob").then((response) =>{
			handleFileDownload(response,"pdf");
		});
	}

    const paginationShowingDetails = () =>{
		if(paginationData.totalElements < paginationData.size)
			return `1 to ${paginationData.totalElements}`;
		else{
			if(paginationData.last)
				return `${ 1 + ( (paginationData.number ) * paginationData.size)} - ${paginationData.totalElements}`;
			else
				return `${ 1 + ( (paginationData.number ) * paginationData.size)} - ${(paginationData.number+1) * paginationData.size}`;
		}
   }

	useEffect(()=>{
		handleSearch();
	},[]);

	return <>
			<div className="row mt-4 p-4">
					<div className="col-12">

						<div className="card mb-3">
							<div className="row m-2">

								<div className="form-check form-switch">
									<input
										checked={advancedSearch}
										onChange={()=>{setAdvancedSearch(!advancedSearch)}}
										id="advancedSearch" className="form-check-input" type="checkbox" />
									<label htmlFor="advancedSearch" className="form-check-label">Advanced Search</label>
								</div>

								{
									advancedSearch ?
										<>

											<div
												className="mb-1"
												onChange={(e)=>{
													const value = e.target.value;
													if(!selectedCriteria.includes(value))
														setSelectedCriteria(prev => ([...prev,value]))
													else {
														setSelectedCriteria(prev => (prev.filter(p => p !== value)));
														//remove also
														if(value === "submittedOn" || value === "whenDidTheAccidentHappen"){
															let startDate = `${value}_start`;
															let endDate = `${value}_end`;
															handleStatePropertyDeletion(startDate);
															handleStatePropertyDeletion(endDate);
														}else
															handleStatePropertyDeletion(value);
													}
												}}
											>
												<label className="form-label"><strong className="text-danger">*</strong> Kindly choose search criterion/criteria</label>
												<br/>
												<div className="row">
													<div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
														<div className="form-check m-3">
															<input checked={selectedCriteria.includes("mostAccurateReflectsAccidentOrIncident")} value="mostAccurateReflectsAccidentOrIncident" className="form-check-input" id="SearchCriteriaA" type="checkbox" name="SearchCriteriaA"/>
															<label className="form-check-label" htmlFor="SearchCriteriaA">Nature of Accident</label>
														</div>
													</div>

													<div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
														<div className="form-check m-3">
															<input checked={selectedCriteria.includes("whatAreYouReportingFor")} value="whatAreYouReportingFor" className="form-check-input" id="SearchCriteriaB" type="checkbox" name="SearchCriteriaB"/>
															<label className="form-check-label" htmlFor="SearchCriteriaB">Type of Accident</label>
														</div>
													</div>

													<div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
														<div className="form-check m-3">
															<input checked={selectedCriteria.includes("whatTypeOfStudentGroupAreYouReportingFor")} value="whatTypeOfStudentGroupAreYouReportingFor" className="form-check-input" id="SearchCriteriaH" type="checkbox" name="SearchCriteriaH"/>
															<label className="form-check-label" htmlFor="SearchCriteriaH">Student Group Type</label>
														</div>
													</div>

													<div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
														<div className="form-check m-3">
															<input checked={selectedCriteria.includes("whatIsTheNameofYourSocietyCluborGroup")} value="whatIsTheNameofYourSocietyCluborGroup" className="form-check-input" id="SearchCriteriaI" type="checkbox" name="SearchCriteriaI"/>
															<label className="form-check-label" htmlFor="SearchCriteriaI">Society Name</label>
														</div>
													</div>

													<div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
														<div className="form-check m-3">
															<input checked={selectedCriteria.includes("whatIsYourSUDepartment")} value="whatIsYourSUDepartment" className="form-check-input" id="SearchCriteriaJ" type="checkbox" name="SearchCriteriaJ"/>
															<label className="form-check-label" htmlFor="SearchCriteriaJ">SU Department</label>
														</div>
													</div>

													<div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
														<div className="form-check m-3">
															<input checked={selectedCriteria.includes("fillerFullName")} value="fillerFullName" className="form-check-input" id="SearchCriteriaC" type="checkbox" name="SearchCriteriaC"/>
															<label className="form-check-label" htmlFor="SearchCriteriaC">Reporter Name</label>
														</div>

													</div>

													<div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
														<div className="form-check m-3">
															<input checked={selectedCriteria.includes("fillerEmailAddress")} value="fillerEmailAddress" className="form-check-input" id="SearchCriteriaD" type="checkbox" name="SearchCriteriaD"/>
															<label className="form-check-label" htmlFor="SearchCriteriaD">Reporter Email</label>
														</div>
													</div>

													<div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
														<div className="form-check m-3">
															<input checked={selectedCriteria.includes("injuredPersonsFullName")} value="injuredPersonsFullName" className="form-check-input" id="SearchCriteriaK" type="checkbox" name="SearchCriteriaK"/>
															<label className="form-check-label" htmlFor="SearchCriteriaK">Injured Person's Name</label>
														</div>
													</div>

													<div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
														<div className="form-check m-3">
															<input checked={selectedCriteria.includes("didTheEventHappenOnAUniversityCampus")} value="didTheEventHappenOnAUniversityCampus" className="form-check-input" id="SearchCriteriaL" type="checkbox" name="SearchCriteriaL"/>
															<label className="form-check-label" htmlFor="SearchCriteriaL">On-Campus Event?</label>
														</div>
													</div>

													<div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
														<div className="form-check m-3">
															<input checked={selectedCriteria.includes("whatCausedTheAccident")} value="whatCausedTheAccident" className="form-check-input" id="SearchCriteriaM" type="checkbox" name="SearchCriteriaM"/>
															<label className="form-check-label" htmlFor="SearchCriteriaM">Accident Event Cause</label>
														</div>
													</div>

													<div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
														<div className="form-check m-3">
															<input checked={selectedCriteria.includes("doAnyFirstAidKitsNeedToBeRefilled")} value="doAnyFirstAidKitsNeedToBeRefilled" className="form-check-input" id="SearchCriteriaO" type="checkbox" name="SearchCriteriaO"/>
															<label className="form-check-label" htmlFor="SearchCriteriaO">First Aid Kits Refills</label>
														</div>
													</div>

													<div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
														<div className="form-check m-3">
															<input checked={selectedCriteria.includes("whatHasBeenDamagedOrBroken")} value="whatHasBeenDamagedOrBroken" className="form-check-input" id="SearchCriteriaN" type="checkbox" name="SearchCriteriaN"/>
															<label className="form-check-label" htmlFor="SearchCriteriaN">What was damaged?</label>
														</div>
													</div>

													<div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
														<div className="form-check m-3">
															<input checked={selectedCriteria.includes("didAnybodyElseWitnessTheEvent")} value="didAnybodyElseWitnessTheEvent" className="form-check-input" id="SearchCriteriaP" type="checkbox" name="SearchCriteriaP"/>
															<label className="form-check-label" htmlFor="SearchCriteriaP">Witness Availability</label>
														</div>
													</div>

													<div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
														<div className="form-check m-3">
															<input checked={selectedCriteria.includes("reportHasBeenRead")} value="reportHasBeenRead" className="form-check-input" id="SearchCriteriaE" type="checkbox" name="SearchCriteriaE"/>
															<label className="form-check-label" htmlFor="SearchCriteriaE">Read Status</label>
														</div>
													</div>

													<div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
														<div className="form-check m-3">
															<input checked={selectedCriteria.includes("whenDidTheAccidentHappen")} value="whenDidTheAccidentHappen" className="form-check-input" id="SearchCriteriaF" type="checkbox" name="SearchCriteriaF"/>
															<label className="form-check-label" htmlFor="SearchCriteriaF">Date of Event</label>
														</div>
													</div>

													<div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
														<div className="form-check m-3">
															<input checked={selectedCriteria.includes("submittedOn")} value="submittedOn" className="form-check-input" id="SearchCriteriaG" type="checkbox" name="SearchCriteriaG"/>
															<label className="form-check-label" htmlFor="SearchCriteriaG">Date of Submission</label>
														</div>
													</div>

												</div>
											</div>

											<div className="row">

												{selectedCriteria.includes("mostAccurateReflectsAccidentOrIncident")?
													<div className="col-12 p-3">
														<div className="input-group input-group-static">
															<label htmlFor="mostAccurateReflectsAccidentOrIncident" style={{ fontWeight:"bold", fontSize:"14px", paddingBottom : "10px" }}>Select Nature of Accident</label>
															<select
																className="form-control"
																name="mostAccurateReflectsAccidentOrIncident"
																id="mostAccurateReflectsAccidentOrIncident"
																autoComplete="off"
																value={advancedSearchForm?.mostAccurateReflectsAccidentOrIncident}
																onChange={e=>{
																	setAdvancedSearchForm(prevState => ({...prevState,[e.target.name]:e.target.value}));
																}}
															>
																<option value="">--Select Status--</option>
																<option value="STUDENT - The Accident/Incident occurred at a STUDENT - Led activity (e.g. Society, Club, SRS or Network activity)">STUDENT</option>
																<option value="STAFF - The Accident Involved an SU Staff Member or happened to a member of the public at an SU Place of Work">STAFF</option>
																<option value="None of the above">NONE</option>
															</select>
														</div>
													</div>:null}

												{selectedCriteria.includes("whatAreYouReportingFor")?
													<div className="col-12 p-3">
														<div className="input-group input-group-static">
															<label htmlFor="whatAreYouReportingFor" style={{ fontWeight:"bold", fontSize:"14px", paddingBottom : "10px" }}>Type of Accident</label>
															<select
																className="form-control"
																name="whatAreYouReportingFor"
																id="whatAreYouReportingFor"
																autoComplete="off"
																value={advancedSearchForm?.whatAreYouReportingFor}
																onChange={e=>{
																	setAdvancedSearchForm(prevState => ({...prevState,[e.target.name]:e.target.value}));
																}}
															>
																<option value="">--Select Status--</option>
																<option value="ACCIDENT - Injury">ACCIDENT - Injury</option>
																<option value="ACCIDENT - Damage">ACCIDENT - Damage</option>
																<option value="INCIDENT">INCIDENT</option>
																<option value="NEAR-MISS">NEAR-MISS</option>
															</select>
														</div>
													</div>:null}

												{selectedCriteria.includes("whatTypeOfStudentGroupAreYouReportingFor")?
													<div className="col-12 p-3">
														<div className="input-group input-group-static">
															<label htmlFor="whatTypeOfStudentGroupAreYouReportingFor" style={{ fontWeight:"bold", fontSize:"14px", paddingBottom : "10px" }}>Type of Student Group</label>
															<select
																className="form-control"
																name="whatTypeOfStudentGroupAreYouReportingFor"
																id="whatTypeOfStudentGroupAreYouReportingFor"
																autoComplete="off"
																value={advancedSearchForm?.whatTypeOfStudentGroupAreYouReportingFor}
																onChange={e=>{
																	setAdvancedSearchForm(prevState => ({...prevState,[e.target.name]:e.target.value}));
																}}
															>
																<option value="">--Please select an option--</option>
																<option value="UP Based Society">UP Based Society</option>
																<option value="Sports Club (UonSport Affiliated)">Sports Club (UonSport Affiliated)</option>
																<option value="SB Based Society / Club">SB Based Society / Club</option>
																<option value="MedSoc Club / Society">MedSoc Club / Society</option>
																<option value="UNAD Club / Society">UNAD Club / Society</option>
																<option value="NMA /SUPRA">NMA /SUPRA</option>
																<option value="A Network">A Network</option>
																<option value="TEC PA & Lighting">TEC PA & Lighting</option>
																<option value="Student Run Services e.g (URN,NNT)">Student Run Services e.g (URN,NNT)</option>
																<option value="Associated Body e.g (SBGuild, Medsoc, UNAD e.t.c)">Associated Body e.g (SBGuild, Medsoc, UNAD e.t.c)</option>
																<option value="Other">Other</option>
															</select>
														</div>
													</div>:null}

												{selectedCriteria.includes("whatIsYourSUDepartment")?
													<div className="col-12 p-3">
														<div className="input-group input-group-static">
															<label htmlFor="whatIsYourSUDepartment" style={{ fontWeight:"bold", fontSize:"14px", paddingBottom : "10px" }}>SU Department</label>
															<select
																className="form-control"
																name="whatIsYourSUDepartment"
																id="whatIsYourSUDepartment"
																autoComplete="off"
																value={advancedSearchForm?.whatIsYourSUDepartment}
																onChange={e=>{
																	setAdvancedSearchForm(prevState => ({...prevState,[e.target.name]:e.target.value}));
																}}
															>
																<option value="">--Please select an option--</option>
																{
																	suDepartments.length?
																		suDepartments.map(
																			(d)=>(<option key={d.departmentName} value={d.departmentName}>{d.departmentName}</option>)
																		)
																		:
																		null
																}
															</select>
														</div>
													</div>:null}

												{selectedCriteria.includes("injuredPersonsFullName") ?
													<div className="col-12 p-3">
														<div className="input-group input-group-static">
															<label htmlFor="injuredPersonsFullName" style={{ fontWeight:"bold", fontSize:"14px", paddingBottom : "10px" }}>Injured Person's Name</label>
															<input
																autoComplete="off"
																type="text"
																name="injuredPersonsFullName"
																id="injuredPersonsFullName"
																className="form-control"
																placeholder="Injured Person's Name (First Name , Last name or Both)"
																value={advancedSearchForm?.injuredPersonsFullName}
																onChange={e=>{
																	setAdvancedSearchForm(prevState => ({...prevState,[e.target.name]:e.target.value}));
																}}
															/>
														</div>
													</div>:null}

												{selectedCriteria.includes("didTheEventHappenOnAUniversityCampus") ?
													<div className="col-12 p-3">
														<div className="input-group input-group-static">
															<label htmlFor="didTheEventHappenOnAUniversityCampus" style={{ fontWeight:"bold", fontSize:"14px", paddingBottom : "10px" }}>On-Campus Event?</label>
															<select
																className="form-control"
																name="didTheEventHappenOnAUniversityCampus"
																id="didTheEventHappenOnAUniversityCampus"
																autoComplete="off"
																value={advancedSearchForm?.didTheEventHappenOnAUniversityCampus}
																onChange={e=>{
																	setAdvancedSearchForm(prevState => ({...prevState,[e.target.name]:e.target.value}));
																}}
															>
																<option value="">--Select Status--</option>
																<option value="YES">YES</option>
																<option value="NO">NO</option>
															</select>
														</div>
													</div>:null}

												{selectedCriteria.includes("whatIsTheNameofYourSocietyCluborGroup") ?
													<div className="col-12 p-3">
														<div className="input-group input-group-static">
															<label htmlFor="whatIsTheNameofYourSocietyCluborGroup" style={{ fontWeight:"bold", fontSize:"14px", paddingBottom : "10px" }}>Society Club or Group Name</label>
															<input
																autoComplete="off"
																type="text"
																name="whatIsTheNameofYourSocietyCluborGroup"
																id="whatIsTheNameofYourSocietyCluborGroup"
																className="form-control"
																placeholder="Search by Society Club or Name"
																value={advancedSearchForm?.whatIsTheNameofYourSocietyCluborGroup}
																onChange={e=>{
																	setAdvancedSearchForm(prevState => ({...prevState,[e.target.name]:e.target.value}));
																}}
															/>
														</div>
													</div>:null}

												{selectedCriteria.includes("whatCausedTheAccident") ?
													<div className="col-12 p-3">
														<div className="input-group input-group-static">
															<label htmlFor="whatCausedTheAccident" style={{ fontWeight:"bold", fontSize:"14px", paddingBottom : "10px" }}>What caused the Accident?</label>
															<select
																className="form-control"
																name="whatCausedTheAccident"
																id="whatCausedTheAccident"
																autoComplete="off"
																value={advancedSearchForm?.whatCausedTheAccident}
																onChange={e=>{
																	setAdvancedSearchForm(prevState => ({...prevState,[e.target.name]:e.target.value}));
																}}
															>
																<option value="">--Please select an option</option>
																<option value="Contact / Collision between People">Contact / Collision between People</option>
																<option value="Slip or Trip">Slip or Trip</option>
																<option value="Falls from height">Falls from height</option>
																<option value="Tool, Equipment or Machinery">Tool, Equipment or Machinery</option>
																<option value="Fire or Heat Contact">Fire or Heat Contact</option>
																<option value="Handling or Lifting">Handling or Lifting</option>
																<option value="Impact with a fixed / non-moving object">Impact with a fixed / non-moving object</option>
																<option value="Hit by a moving object (NOT Vehicle)">Hit by a moving object (NOT Vehicle)</option>
																<option value="Hit by a moving object">Hit by a moving object</option>
																<option value="Contact with Electricity">Contact with Electricity</option>
																<option value="Contact with a hazardous substance (e.g. Chemicals)">Contact with a hazardous substance (e.g. Chemicals)</option>
																<option value="Cold (hypothermia)">Cold (hypothermia)</option>
																<option value="Water Related (e.g submersion, inhalation of water)">Water Related (e.g submersion, inhalation of water)</option>
																<option value="Air or Water craft">Air or Water craft</option>
																<option value="Weapon">Weapon</option>
																<option value="Other">Other</option>
															</select>
														</div>
													</div>:null}

												{selectedCriteria.includes("doAnyFirstAidKitsNeedToBeRefilled") ?
													<div className="col-12 p-3">
														<div className="input-group input-group-static">
															<label htmlFor="doAnyFirstAidKitsNeedToBeRefilled" style={{ fontWeight:"bold", fontSize:"14px", paddingBottom : "10px" }}>First Aid Kit refills required?</label>
															<select
																className="form-control"
																name="doAnyFirstAidKitsNeedToBeRefilled"
																id="doAnyFirstAidKitsNeedToBeRefilled"
																autoComplete="off"
																value={advancedSearchForm?.doAnyFirstAidKitsNeedToBeRefilled}
																onChange={e=>{
																	setAdvancedSearchForm(prevState => ({...prevState,[e.target.name]:e.target.value}));
																}}
															>
																<option value="">--Select Status--</option>
																<option value="YES">YES</option>
																<option value="NO">NO</option>
															</select>
														</div>
													</div>:null}

												{selectedCriteria.includes("whatHasBeenDamagedOrBroken") ?
													<div className="col-12 p-3">
														<div className="input-group input-group-static">
															<label htmlFor="whatCausedTheAccident" style={{ fontWeight:"bold", fontSize:"14px", paddingBottom : "10px" }}> What has been damaged or broken?</label>
															<select
																className="form-control"
																name="whatHasBeenDamagedOrBroken"
																id="whatHasBeenDamagedOrBroken"
																autoComplete="off"
																value={advancedSearchForm?.whatHasBeenDamagedOrBroken}
																onChange={e=>{
																	setAdvancedSearchForm(prevState => ({...prevState,[e.target.name]:e.target.value}));
																}}
															>
																<option value="">--Please select an option</option>
																<option value="Equipment, Tools or Machinery (NOT Vehicle)">Equipment, Tools or Machinery (NOT Vehicle)</option>
																<option value="Property or Buildings - UNIVERSITY OWNED">Property or Buildings - UNIVERSITY OWNED</option>
																<option value="Property or Buildings - OTHER">Property or Buildings - OTHER</option>
																<option value="Vehicle">Vehicle</option>
																<option value="Other">Other</option>
															</select>
														</div>
													</div>:null}

												{selectedCriteria.includes("didAnybodyElseWitnessTheEvent") ?
													<div className="col-12 p-3">
														<div className="input-group input-group-static">
															<label htmlFor="didAnybodyElseWitnessTheEvent" style={{ fontWeight:"bold", fontSize:"14px", paddingBottom : "10px" }}>Where witnesses present?</label>
															<select
																className="form-control"
																name="didAnybodyElseWitnessTheEvent"
																id="didAnybodyElseWitnessTheEvent"
																autoComplete="off"
																value={advancedSearchForm?.didAnybodyElseWitnessTheEvent}
																onChange={e=>{
																	setAdvancedSearchForm(prevState => ({...prevState,[e.target.name]:e.target.value}));
																}}
															>
																<option value="">--Select Status--</option>
																<option value="YES">YES</option>
																<option value="NO">NO</option>
															</select>
														</div>
													</div>:null}

												{selectedCriteria.includes("fillerFullName") ?
													<div className="col-12 p-3">
														<div className="input-group input-group-static">
															<label htmlFor="fillerFullName" style={{ fontWeight:"bold", fontSize:"14px", paddingBottom : "10px" }}>Search by Reporter Name</label>
															<input
																autoComplete="off"
																type="text"
																name="fillerFullName"
																id="fillerFullName"
																className="form-control"
																placeholder="Search by Name"
																value={advancedSearchForm?.fillerFullName}
																onChange={e=>{
																	setAdvancedSearchForm(prevState => ({...prevState,[e.target.name]:e.target.value}));
																}}
															/>
														</div>
													</div>:null}

												{selectedCriteria.includes("fillerEmailAddress") ?
													<div className="col-12 p-3">
														<div className="input-group input-group-static">
															<label htmlFor="fillerEmail" style={{ fontWeight:"bold", fontSize:"14px", paddingBottom : "10px" }}>Search by Reporter Email</label>
															<input
																autoComplete="off"
																type="text"
																name="fillerEmailAddress"
																id="fillerEmailAddress"
																className="form-control"
																placeholder="Search by Email"
																value={advancedSearchForm?.fillerEmailAddress}
																onChange={e=>{
																	setAdvancedSearchForm(prevState => ({...prevState,[e.target.name]:e.target.value}));
																}}
															/>
														</div>
													</div>:null}

												{selectedCriteria.includes("reportHasBeenRead")?
													<div className="col-12 p-3">
														<div className="input-group input-group-static">
															<label htmlFor="reportHasBeenRead" style={{ fontWeight:"bold", fontSize:"14px", paddingBottom : "10px" }}>Report Read Status</label>
															<select
																className="form-control"
																name="reportHasBeenRead"
																id="reportHasBeenRead"
																autoComplete="off"
																value={advancedSearchForm?.reportHasBeenRead}
																onChange={e=>{
																	setAdvancedSearchForm(prevState => ({...prevState,[e.target.name]:e.target.value}));
																}}
															>
																<option value="">--Select Status--</option>
																<option value="1">Read</option>
																<option value="2">Unread</option>
															</select>
														</div>
													</div>:null}

												{selectedCriteria.includes("whenDidTheAccidentHappen") ?
													<div className="row p-3">
														<h6 style={{fontSize: "14px"}}>Date of Event</h6>
														<div className="col-12">
															<div className="row">

																<div className="col-6">
																	<div className="input-group input-group-static">
																		<label htmlFor="">Start Date</label>
																		<Datetime
																			className="input-group input-group-static"
																			closeOnSelect={true}
																			dateFormat={"YYYY-MM-DD"}
																			timeFormat={false}
																			inputProps={{
																				name: "whenDidTheAccidentHappen_start",
																				value: advancedSearchForm?.whenDidTheAccidentHappen_start
																			}}
																			onChange={(e) => {
																				const newDay = moment(new Date(e._d)).format(
																					"YYYY-MM-DD"
																				);
																				setAdvancedSearchForm(prevState => ({...prevState,"whenDidTheAccidentHappen_start":newDay,"whenDidTheAccidentHappen_end":""}));
																			}}
																			/* isValidDate={(current) =>
                                                                                current.isAfter(moment().subtract(1, "day"))
                                                                            } */
																		/>
																	</div>
																</div>

																<div className="col-6">
																	<div className="input-group input-group-static">
																		<label htmlFor="">End Date</label>
																		<Datetime
																			className="input-group input-group-static"
																			closeOnSelect={true}
																			dateFormat={"YYYY-MM-DD"}
																			timeFormat={false}
																			inputProps={{
																				name: "whenDidTheAccidentHappen_end",
																				value: advancedSearchForm?.whenDidTheAccidentHappen_end
																			}}
																			onChange={(e) => {
																				const newDay2 = moment(new Date(e._d)).format(
																					"YYYY-MM-DD"
																				);
																				setAdvancedSearchForm(prevState => ({...prevState,"whenDidTheAccidentHappen_end":newDay2}));
																			}}
																			isValidDate={(current) =>
																				current.isAfter(moment(advancedSearchForm?.whenDidTheAccidentHappen_start))
																			}
																		/>
																	</div>
																</div>

															</div>
														</div>
													</div>
													:null}

												{selectedCriteria.includes("submittedOn")?
													<div className="row p-3">
														<h6 style={{fontSize: "14px"}}>Date Submitted</h6>
														<div className="col-12">
															<div className="row">

																<div className="col-6">
																	<div className="input-group input-group-static">
																		<label htmlFor="">Start Date</label>
																		<Datetime
																			className="input-group input-group-static"
																			closeOnSelect={true}
																			dateFormat={"YYYY-MM-DD"}
																			timeFormat={false}
																			inputProps={{
																				name: "submittedOn_start",
																				value: advancedSearchForm?.submittedOn_start,
																			}}
																			onChange={(e) => {
																				const newDay = moment(new Date(e._d)).format(
																					"YYYY-MM-DD"
																				);

																				setAdvancedSearchForm(prevState => ({...prevState,"submittedOn_start":newDay,"submittedOn_end":""}));

																			}}
																			/* isValidDate={(current) =>
                                                                                current.isAfter(moment().subtract(1, "day"))
                                                                            } */
																		/>
																	</div>
																</div>

																<div className="col-6">
																	<div className="input-group input-group-static">
																		<label htmlFor="">End Date</label>
																		<Datetime
																			className="input-group input-group-static"
																			closeOnSelect={true}
																			dateFormat={"YYYY-MM-DD"}
																			timeFormat={false}
																			inputProps={{
																				name: "submittedOn_end",
																				value: advancedSearchForm?.submittedOn_end
																			}}
																			onChange={(e) => {
																				const newDay2 = moment(new Date(e._d)).format(
																					"YYYY-MM-DD"
																				);
																				setAdvancedSearchForm(prevState => ({...prevState,"submittedOn_end":newDay2}));
																			}}
																			isValidDate={(current) =>
																				current.isAfter(moment(advancedSearchForm?.submittedOn_start))
																			}
																		/>
																	</div>
																</div>

															</div>
														</div>
													</div>
													:null}
											</div>
										</>
										:
										null}


								{advancedSearch && selectedCriteria.length > 0?
									<>
										<div className="row px-md-6 px-lg-12">
											<button onClick={()=>handleSearch(advancedSearchForm,0,paginationData.size,"filter")} type="button" className="m-2 btn btn-icon btn-outline-dark">
												<span className="btn-inner--text"><span className="btn-inner--icon"> <i className="material-icons">search</i></span> SEARCH</span>
											</button>
										</div>
									</>:null}
							</div>
						</div>

						<div className="card mb-4">

							{searchPerformed && paginationData.loaded?
							<div style={{padding: "5px"}} className="alert alert-secondary text-white m-2" role="alert">
								  <span style={{padding: "5px"}} className="alert-icon align-middle">
									<i className="material-icons-round">privacy_tip</i>
								  </span>
								<span className="alert-text"> Your query matched <span className="badge badge-sm badge-success">{paginationData.totalElements}</span> result(s)</span>
							</div>:null}

							{searchPerformed && paginationData.loaded?
							<div style={{display: "flex"}}>
							<button onClick={()=>handleClearFilter()} style={{marginInline: "10px", paddingInline: "5px", paddingBlock: "0px", width: "120px", display: "flex", justifyContent: "center", alignItems: "center"}} className="btn btn-icon btn-3 btn-danger" type="button">
								<span className="btn-inner--icon"><i style={{fontSize: "14px"}} className="material-icons-round">delete_forever</i> </span>
								<span style={{textTransform: "none", fontSize: "13px", fontWeight: 400}} className="btn-inner--text">&nbsp;Clear Filter</span>
							</button>

								{paginationData.totalElements > 0?
									<>
									<AuthorizedAreas permission="CAN_ACCIDENT_REPORT_DOWNLOAD_REPORTS_PDF">
										<button onClick={()=>handleSearch(advancedSearchForm,0,paginationData.size,"pdf")} style={{marginInline: "10px", paddingInline: "5px", paddingBlock: "0px", width: "120px", display: "flex", justifyContent: "center", alignItems: "center"}} className="btn btn-icon btn-3 btn-outline-dark" type="button">
											<span className="btn-inner--icon"><i style={{fontSize: "14px"}} className="material-icons-round">picture_as_pdf</i> </span>
											<span style={{textTransform: "none", fontSize: "13px", fontWeight: 400}} className="btn-inner--text">&nbsp;Download PDF</span>
										</button>
									</AuthorizedAreas>

									<AuthorizedAreas permission="CAN_ACCIDENT_REPORT_DOWNLOAD_REPORTS_XLSX">
										<button onClick={()=>handleSearch(advancedSearchForm,0,paginationData.size,"excel")}  style={{marginInline: "10px", paddingInline: "5px", paddingBlock: "0px", width: "120px", display: "flex", justifyContent: "center", alignItems: "center"}} className="btn btn-icon btn-3 btn-outline-dark" type="button">
											<span className="btn-inner--icon"><i style={{fontSize: "14px"}} className="fa-regular fa-file-excel"></i> </span>
											<span style={{textTransform: "none", fontSize: "13px", fontWeight: 400}} className="btn-inner--text">&nbsp;Download Excel</span>
										</button>
									</AuthorizedAreas>
									</>
									:
									null}
							</div>
								:null}



							<div className="dataTable-top">
								<div style={{float:"right"}} className="dataTable-dropdown"><label>
									<select
										onChange={(e)=>setPageSize(e.target.value)}
										className="dataTable-selector">
										<option value="25">25</option>
										<option value="50">50</option>
										<option value="100">100</option>
									</select> entries per page</label>
								</div>
								<div className="dataTable-search d-none">
									<input className="dataTable-input" placeholder="Filter..." type="text"/>
								</div>
							</div>

							<div className="card-header pb-0">
								<h6>Accidents Report Manager</h6>
							</div>
							<div className="card-body px-0 pt-0 pb-2">
								<AuthorizedAreas permission="CAN_SUB_MENU_ACCIDENT_REPORT_MANAGE">
									<div className="table-responsive p-0">
										
										<table ref={searchResultRef} className="table align-items-center mb-0">
											<thead>
												<tr>
													{/* <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														S/N
													</th> */}
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														Filled By
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														Date of Event
													</th>

													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														Date Submitted
													</th>
													
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														Type of Event
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
														Viewed?
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"></th>
												</tr>
											</thead>
											<tbody>
												{allData.length > 0 ? (
													allData.map((s, index) => (
														<tr key={`${s.reportId}`}>
															{/* <td className="text-center">
																<p className="text-sm font-weight-normal mb-0">
																	{index + 1}
																</p>
															</td> */}
															<td>
																<div className="d-flex px-3 py-1">
												
																	<div className="d-flex flex-column justify-content-center">
																		<h6 className="mb-0 text-sm">
																			Name : {s.fillerFullName }<br/>
																			Email: {s.fillerEmailAddress }<br/>
																			{
																				s.fillerAdditionalContact.trim() !== "" ?
																				<>
																				Additional: {s.fillerAdditionalContact}
																				</>:
																				null
																			}

																		</h6>
																	</div>
																</div>
															</td>
															<td>
																<div className="d-flex px-3 py-1">
												
																	<div className="d-flex flex-column justify-content-center">
																		<h6 className="mb-0 text-sm">
																			<span className="">{ moment(s.whenDidTheAccidentHappen).format('LLL') }</span>
																		</h6>
																	</div>
																</div>
															</td>

															<td>
																<div className="d-flex px-3 py-1">

																	<div className="d-flex flex-column justify-content-center">
																		<h6 className="mb-0 text-sm">
																			{ moment(s.submittedOn).format('LLL') }
																		</h6>
																	</div>
																</div>
															</td>

															<td>
																<div className="d-flex px-3 py-1">

																	<div className="d-flex flex-column justify-content-center">
																		<span className={`btn btn-sm btn-${typeBadgeSelector(s.whatAreYouReportingFor.toUpperCase())}`}>
																			{ s.whatAreYouReportingFor.toUpperCase() }
																		</span>
																	</div>
																</div>
															</td>

															<td>
																<p className="font-weight-normal mb-0">
																	<span className={`btn btn-sm btn-${s.reportHasBeenRead === 2 ? "outline-warning" : "outline-success"}`}>
																	{s.reportHasBeenRead === 2 ? "UNREAD" : "READ"}
																	</span>
																</p>
															</td>

															<td className="align-middle text-center text-sm">
																<div className="btn-group dropstart">
																	<button
																		className="btn btn-github dropdown-toggle"
																		type="button"
																		id="dropdownMenuButton2"
																		data-bs-toggle="dropdown"
																		aria-expanded="false"
																	>
																		Actions
																	</button>
																	<ul className="dropdown-menu dropdown-menu-dark">
																		<AuthorizedAreas permission="CAN_SUB_MENU_ACCIDENT_REPORT_MANAGE">
																			<li>
																				<Link
																					className="dropdown-item"
																					to={`#`}
																					state={{id: s.reportId}}
																					onClick={()=>viewReport(s.reportId)}
																				>
																					VIEW REPORT
																				</Link>
																			</li>
																		</AuthorizedAreas>


																		<AuthorizedAreas permission="CAN_ACCIDENT_REPORT_MARK_READ">
																			<li>
																				<Link
																					className="dropdown-item"
																					to="#"
																					onClick={()=>handleMarkReadUnread(s.reportId,s.reportHasBeenRead === 1 ? 2: 1)}
																				>
																					MARK AS {s.reportHasBeenRead === 2 ? "READ" : "UNREAD"}
																				</Link>
																			</li>
																		</AuthorizedAreas>

																		<AuthorizedAreas permission="CAN_ACCIDENT_REPORT_DOWNLOAD_REPORTS_XLSX">
																			<li>
																				<Link
																					className="dropdown-item"
																					to={`#`}
																					state={{id: s.reportId}}
																					onClick={()=>downloadExcel(s.reportId)}
																				>
																					DOWNLOAD AS EXCEL
																				</Link>
																			</li>
																		</AuthorizedAreas>

																		<AuthorizedAreas permission="CAN_ACCIDENT_REPORT_DOWNLOAD_REPORTS_XLSX">
																			<li>
																				<Link
																					className="dropdown-item"
																					to={`#`}
																					state={{id: s.reportId}}
																					onClick={()=>downloadPdf(s.reportId)}
																				>
																					DOWNLOAD AS PDF
																				</Link>
																			</li>
																		</AuthorizedAreas>


																	</ul>
																</div>
															</td>
														</tr>
													))
												) : null}
											</tbody>
										</table>
									</div>


									<div className="dataTable-bottom">
										{paginationData.loaded && paginationData.totalElements > 0 ?
											<div className="dataTable-info">Showing {paginationShowingDetails()} of {paginationData.totalElements} entries</div>
											:null}

										{paginationData.totalElements && paginationData.totalPages > 1 ?
										<nav className="dataTable-pagination">
											<ul className="dataTable-pagination-list">
												<li className={`pager ${paginationData.number > 0  ? 'cursor-pointer': 'disabled'}`}>
													<Link onClick={()=>handleSearch(advancedSearchForm,paginationData.number - 1,paginationData.size, "paginate")} to={undefined} >‹</Link>
												</li>
												{paginationData.first!==true ?
												<li className={` ${paginationData.first === true ? 'active': 'cursor-pointer'}`}>
													<Link onClick={()=>{ if(paginationData.number === 0) return; handleSearch(advancedSearchForm,0,paginationData.size, "paginate")}} to="#">1</Link>
												</li> :null}

												{/*<li className=""><Link to="#" >2</Link></li>*/}

												{
													getPaginationBody().map(el=> (
															<li key={el} className={`${el===paginationData.number ? 'active':'cursor-pointer'}`}>
																<Link onClick={()=>{ if(paginationData.number === el) return; handleSearch(advancedSearchForm,el,paginationData.size,"paginate")}} to="#" >{el+1}</Link>
															</li>
													))
												}

												<li className={`page-item ${paginationData.number === paginationData.totalPages-1  ? 'active': 'cursor-pointer'}`}>
													<Link onClick={()=>{ if(paginationData.number === paginationData.totalPages-1) return; handleSearch(advancedSearchForm,paginationData.totalPages-1,paginationData.size, "paginate")}} to="#" >{paginationData.totalPages}</Link>
												</li>

												<li className={`pager ${paginationData.last !== true ? 'cursor-pointer': 'disabled'}`}>
													<Link onClick={()=>handleSearch(advancedSearchForm,paginationData.number+1,paginationData.size, "paginate")} to={undefined} >›</Link>
												</li>

											</ul>
										</nav>:null}

									</div>

								</AuthorizedAreas>
							</div>
						</div>
					</div>


		   </div>

			{/*Modal*/}
			<Modal
				show={showModal}
				fullscreen={false}
				size="xl"
				scrollable={false}
				centered
				backdrop="static"
				keyboard={false}
				onHide={()=>setShowModal(false)}>
				<Modal.Header closeButton={false}>
					<Modal.Title>Report Details <i style={{ position:'absolute', right:'15px', top: '10px', cursor:"pointer", fontSize:"35px", color:"#24292e" }}  onClick={()=>setShowModal(false)} className="fa fa-circle-xmark"></i></Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="container">

						{/*Section 1*/}
						<div>
								<div className="mb-5">
									<label className="form-label"> <strong className="text-danger">*</strong> Which of the following statements <strong>MOST ACCURATELY</strong> reflects the accident/incident?</label>
									<input className="form-control" readOnly={true} value={singleReportData.mostAccurateReflectsAccidentOrIncident} type="text" />
								</div>

							{
								singleReportData.mostAccurateReflectsAccidentOrIncident === "STUDENT - The Accident/Incident occurred at a STUDENT - Led activity (e.g. Society, Club, SRS or Network activity)"?
									<>
										<div className="mb-5">
											<label className="form-label"> <strong className="text-danger">*</strong> What Type of Student Group are you reporting for?</label>
											<input className="form-control" readOnly={true} value={singleReportData.whatTypeOfStudentGroupAreYouReportingFor} type="text" />
										</div>

										<div className="mb-5">
											<label className="form-label"> <strong className="text-danger">*</strong> What is the Name of your Society, Club or Group?</label>
											<input className="form-control" readOnly={true} value={singleReportData.whatIsTheNameofYourSocietyCluborGroup} type="text" />
										</div>
									</>
									:
									null
							}

							{
								singleReportData.mostAccurateReflectsAccidentOrIncident === "None of the above" || singleReportData.mostAccurateReflectsAccidentOrIncident === "STAFF - The Accident Involved an SU Staff Member or happened to a member of the public at an SU Place of Work"?
									<>
										<div className="mb-5">
											<label className="form-label"> <strong className="text-danger">*</strong> What is your SU Department?</label>
											<input className="form-control" readOnly={true} value={singleReportData.whatIsYourSUDepartment} type="text" />
										</div>
									</>
									:
									null
							}

							<div className="mb-5">
								<label className="form-label"> <strong className="text-danger">*</strong> What are you Reporting?</label>
								<input className="form-control" readOnly={true} value={singleReportData.whatAreYouReportingFor} type="text" />
							</div>
						</div>

						{/*Section 2 ACCIDENT - INJURY*/}
						<div>

							{
								singleReportData.whatAreYouReportingFor === "ACCIDENT - Injury" ?
								<>
									<h4 style={{ color : "#171635"}}><i className="bi bi-patch-question-fill"></i> About the person who had the Accident</h4>

									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> Injured Person's Full Name?</label>
										<input className="form-control" readOnly={true} value={singleReportData.injuredPersonsFullName} type="text" />
									</div>

									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> Injured Person's Address?</label>
										<input className="form-control" readOnly={true} value={singleReportData.injuredPersonsAddress} type="text" />
									</div>

									{
										singleReportData.injuredPersonsPostcode.trim() !== "" ?
									<div className="mb-5">
										<label className="form-label"> Injured Person's Postcode?</label>
										<input className="form-control" readOnly={true} value={singleReportData.injuredPersonsPostcode} type="text" />
									</div>
										:
										null
									}

									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> Injured Person's Age?</label>
										<input className="form-control" readOnly={true} value={singleReportData.injuredPersonsAge} type="text" />
									</div>

									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> What Injuries has the person suffered?</label>
										<textarea className="form-control mt-3" readOnly={true} value={singleReportData.injuredPersonsInjuryType} type="text" style={{height: "7rem"}}></textarea>
									</div>

									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> Where on the body are the injuries?</label>
										<textarea className="form-control mt-3" readOnly={true} value={singleReportData.injuredPersonsInjuryLocation} type="text" style={{height: "7rem"}}></textarea>
									</div>

									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> What First Aid Treatment was given on the scene?</label>
										<textarea className="form-control mt-3" readOnly={true} value={singleReportData.injuredPersonsFirstAid} type="text" style={{height: "7rem"}}></textarea>
									</div>

									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> Does the Injured Person give permission for personal and/or contact information to be passed on to the University?</label>
										<input className="form-control" readOnly={true} value={singleReportData.injuredPersonsPermission} type="text" />
									</div>


								</>
								:
								null
							}

						</div>

						{/*Section 3 About Event*/}
						<div>
							<h4 style={{ color : "#171635"}}><i className="bi bi-question-square-fill"></i> About the <strong>{singleReportData.whatAreYouReportingFor}</strong></h4>

							<div className="mb-5">
								<label className="form-label"> <strong className="text-danger">*</strong> Did the {singleReportData.whatAreYouReportingFor} happen on a University Campus (or University owned property)?</label>
								<input className="form-control" readOnly={true} value={singleReportData.didTheEventHappenOnAUniversityCampus} type="text" />
							</div>

							<div className="mb-5">
								<label className="form-label"> <strong className="text-danger">*</strong> <strong>WHERE</strong> did the {singleReportData.whatAreYouReportingFor} Happen?</label>
								<textarea className="form-control mt-3" readOnly={true} value={singleReportData.whereDidTheEventHappen} type="text" style={{height: "7rem"}}></textarea>
							</div>

							<div className="mb-5">
								<label className="form-label"> <strong className="text-danger">*</strong> <strong>WHEN</strong> did the {singleReportData.whatAreYouReportingFor} Happen?</label>
								<input className="form-control" readOnly={true} value={moment(new Date(singleReportData.whenDidTheAccidentHappen)).format("YYYY-MM-DD")} type="text" />
							</div>

							<div className="mb-5">
								<label className="form-label"> <strong className="text-danger">*</strong> Please describe how the {singleReportData.whatAreYouReportingFor} happened</label>
								<textarea className="form-control mt-3" readOnly={true} value={singleReportData.pleaseDescribeHowTheEventHappened} type="text" style={{height: "7rem"}}></textarea>
							</div>

							{
								singleReportData.whatAreYouReportingFor === "ACCIDENT - Injury" ?
								<>
									<div className="mb-5">
										<label className="form-label"><strong className="text-danger">*</strong> After the Injury Accident - Did any of the following occur?</label>
										{
											singleReportData.afterTheInjuryDidAnyOfTheFollowingINJURY.trim() !== "" && singleReportData.afterTheInjuryDidAnyOfTheFollowingINJURY.includes(";")?

												<>
												{
													singleReportData.afterTheInjuryDidAnyOfTheFollowingINJURY.split(";").map(answer=>
													  <div className="form-check mt-3">
														 <input checked={true} readOnly={true} className="form-check-input" type="checkbox" value={answer} />
														 <label className="form-check-label">{answer}</label>
													  </div>
													)
												})

												</>
												:
												<div className="form-check mt-3">
													<input checked={true} readOnly={true} className="form-check-input" type="checkbox" value={singleReportData.afterTheInjuryDidAnyOfTheFollowingINJURY} />
													<label className="form-check-label">{singleReportData.afterTheInjuryDidAnyOfTheFollowingINJURY}</label>
												</div>
										}
									</div>

									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> What caused the accident?</label>
										<input className="form-control" readOnly={true} value={singleReportData.whatCausedTheAccident} type="text" />
									</div>

									{singleReportData.whatCausedTheAccident && singleReportData.whatCausedTheAccident==="Other"?
									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> Please state what caused the accident?</label>
										<input className="form-control" readOnly={true} value={singleReportData.whatCausedTheAccidentOtherReason} type="text" />
									</div>:null}

									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> Do any first aid kits need to be refilled after treating this incident?</label>
										<input className="form-control" readOnly={true} value={singleReportData.doAnyFirstAidKitsNeedToBeRefilled} type="text" />
									</div>

								</>
								:
								null
							}

							{
								singleReportData.whatAreYouReportingFor === "ACCIDENT - Damage" ?
									<>
										<div className="mb-5">
											<label className="form-label"><strong className="text-danger">*</strong> What has been damaged or broken?</label>
											{
												singleReportData.whatHasBeenDamagedOrBroken.trim() !== "" && singleReportData.whatHasBeenDamagedOrBroken.includes(";")
													?
													<>
														{
															singleReportData.whatHasBeenDamagedOrBroken.split(";").map(answer=>
																<div className="form-check mt-3">
																	<input checked={true} readOnly={true} className="form-check-input" type="checkbox" value={answer} />
																	<label className="form-check-label">{answer}</label>
																</div>
															)
														}
													</>
													:
													<div className="form-check mt-3">
														<input checked={true} readOnly={true} className="form-check-input" type="checkbox" value={singleReportData.whatHasBeenDamagedOrBroken} />
														<label className="form-check-label">{singleReportData.whatHasBeenDamagedOrBroken}</label>
													</div>
											}
										</div>

										{singleReportData.whatHasBeenDamagedOrBroken.trim() !== "" ?
										<div className="mb-5">
											<label className="form-label"> <strong className="text-danger">*</strong>Please describe what has been damaged or broken</label>
											<textarea className="form-control mt-3" readOnly={true} value={singleReportData.whatHasBeenDamagedOrBrokenDescription} type="text" style={{height: "7rem"}}></textarea>
										</div>:null}
									</>
									:
									null
							}

							{singleReportData.whatAreYouReportingFor === "ACCIDENT - Damage" || singleReportData.whatAreYouReportingFor === "INCIDENT" ?
								<>
									<div className="mb-5">
										<label className="form-label"><strong className="text-danger">*</strong> What has been damaged or broken?</label>
										{
											singleReportData.afterTheEventDidAnyOfTheFollowingOthers.trim() !== "" && singleReportData.afterTheEventDidAnyOfTheFollowingOthers.includes(";") ?
												<>
													{
														singleReportData.afterTheEventDidAnyOfTheFollowingOthers.split(";").map(answer=>
															<div key={answer} className="form-check mt-3">
																<input checked={true} readOnly={true} className="form-check-input" type="checkbox" value={answer} />
																<label className="form-check-label">{answer}</label>
															</div>
														)
													}

												</>
												:
												<div className="form-check mt-3">
													<input checked={true} readOnly={true} className="form-check-input" type="checkbox" value={singleReportData.afterTheEventDidAnyOfTheFollowingOthers} />
													<label className="form-check-label">{singleReportData.afterTheEventDidAnyOfTheFollowingOthers}</label>
												</div>
										}
									</div>

								</>
								:
								null
							}

						</div>

						{/*Section 4 Witnesses*/}
						<div>
							<h4 style={{ color : "#171635"}}><i className="bi bi-people-fill"></i> Witnesses</h4>

							<div className="mb-5">
								<label className="form-label"> <strong className="text-danger">*</strong> Did Anybody Else Witness the Accident/Incident or Near-miss?</label>
								<input className="form-control" readOnly={true} value={singleReportData.didAnybodyElseWitnessTheEvent} type="text" />
							</div>

							{
								singleReportData.didAnybodyElseWitnessTheEvent === "YES" ?
								<>
									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> Witness #1 Name:</label>
										<input className="form-control" readOnly={true} value={singleReportData.witness1Name} type="text" />
									</div>

									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> Witness #1 Contact Details:</label>
										<textarea className="form-control mt-3" readOnly={true} value={singleReportData.witness1Contact} type="text" style={{height: "7rem"}}></textarea>
									</div>

									{
										singleReportData.witness2Name.trim() !== "" ?
											<>
												<div className="mb-5">
													<label className="form-label"> Witness #2 Name:</label>
													<input className="form-control" readOnly={true} value={singleReportData.witness2Name} type="text" />
												</div>

												<div className="mb-5">
													<label className="form-label">  Witness #2 Contact Details:</label>
													<textarea className="form-control mt-3" readOnly={true} value={singleReportData.witness2Contact} type="text" style={{height: "7rem"}}></textarea>
												</div>
											</>
											:null
									}

									{
										singleReportData.additionalWitnesses.trim() !== "" ?
											<>
												<div className="mb-5">
													<label className="form-label"> Add details of any additional witnesses below : </label>
													<textarea className="form-control mt-3" readOnly={true} value={singleReportData.additionalWitnesses} type="text" style={{height: "7rem"}}></textarea>
												</div>
											</>
											:null
									}

								</>
								:
								null
							}

						</div>

						{/*Section 5 Filler Details*/}
						<>
							<div className="mb-5">
								<h4 style={{ color : "#171635"}}><i className="bi bi-person-vcard"></i> About the Person Completing this Form</h4>
							</div>

							<div className="mb-5">
								<label className="form-label"> <strong className="text-danger">*</strong> Your Full Name</label>
								<input className="form-control" readOnly={true} value={singleReportData.fillerFullName} type="text" />
							</div>

							<div className="mb-5">
								<label className="form-label"> <strong className="text-danger">*</strong> Your Email Name</label>
								<input className="form-control" readOnly={true} value={singleReportData.fillerEmailAddress} type="text" />
							</div>

							{singleReportData.fillerAdditionalContact.trim() !== "" ?
							<div className="mb-5">
								<label className="form-label"> <strong className="text-danger">*</strong> Any Additional Contact Details</label>
								<textarea className="form-control mt-3" readOnly={true} value={singleReportData.fillerAdditionalContact} type="text" style={{height: "7rem"}}></textarea>
							</div> : null }
						</>


					</div>
				</Modal.Body>
				<Modal.Footer>
					 <button className="btn btn-github" onClick={()=>setShowModal(false)}>Close</button>
				</Modal.Footer>
			</Modal>

		</>

}

export default AccidentsReportManager