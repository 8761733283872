import useGlobalNotification from "./useGlobalNotification";
import useRefreshToken from "./useRefreshToken";
import axios from '../../api/axios';
import {CHANGE_LOADING_STATE, LOGOUT} from "../../redux/slice/authSlice";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {GENERIC_ERROR_MESSAGE, NO_SERVER_RESPONSE_MESSAGE, SESSION_EXPIRED_MESSAGE} from "../Constants";

const useAxios = () => {
    const {accessToken} = useSelector((state)=>state.auth);
    const {dispatchNotification} = useGlobalNotification();
    const dispatch = useDispatch();
    const {refreshAccessToken} = useRefreshToken();
    const location = useLocation();

    const httpRequest = async (url, method, values={}, additionalHeaders={'content-type': 'application/json'},responseType="json") =>{
        dispatch(CHANGE_LOADING_STATE(true));
        const options = { authorization: `Bearer ${accessToken}`,...additionalHeaders };
        try {
            const response = await axios({
                method,
                url,
                // `responseType` indicates the type of data that the server will respond with
                // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
                //   browser only: 'blob'
                //default is json
                responseType: responseType, // important
                onDownloadProgress: (progressEvent) => {
                    // Access headers from the progress event
                    if(responseType === "blob") {
                        window.infContentDisposition = progressEvent.event.target.getResponseHeader('Content-Disposition');
                    }
                },
                headers: options,
                 data: values
            });
            return response.data;
        }
        catch (err) {
            if(typeof err?.response === "undefined"){
                dispatchNotification('error', NO_SERVER_RESPONSE_MESSAGE);
                return;
            }
            if(err?.response?.status === 403){
                //FORBIDDEN
                const token = await refreshAccessToken();
                if (token) {
                    try{
                        const response = await axios({
                            method,
                            url,
                            headers: { authorization: `Bearer ${token}` },
                            data: {...values}
                        });
                        return response.data;
                    }catch(err){
                        dispatchNotification('error',SESSION_EXPIRED_MESSAGE);
                        dispatch(LOGOUT());
                    }

                } else {
                    dispatchNotification('error',SESSION_EXPIRED_MESSAGE);
                    dispatch(LOGOUT());
                }
            return;
			}
            /*if(err.response.data?.errors) {
                //map expected so below needs refactoring
                let message = `<p>${err?.response?.data?.message}</p><p>You have the following errors:</p>`;
                message  += err.response.data.errors.join("<br/>");
                dispatchNotification('error',message);
                return
            }*/
            if(err?.response?.data?.message)
                dispatchNotification('error',err?.response?.data?.message);
            else {
                dispatchNotification('error', GENERIC_ERROR_MESSAGE);
                // window.location.reload()
            }

            }finally{
                dispatch(CHANGE_LOADING_STATE(false));
            }
    }
    
    return {httpRequest}
}

export default useAxios